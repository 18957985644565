export function generateUniqueId(existingIds) {
    // Funkcja do generowania unikalnego identyfikatora
    let newId = Math.floor(Math.random() * 1000) + 1; // Losowy numer z zakresu 1-1000
    while (existingIds.includes(newId)) {
        newId = Math.floor(Math.random() * 1000) + 1;
    }
    return newId;
}

export function addMath(arrayMath, a, b) {

    console.log('arrayMath',arrayMath)

    let math = '';
    arrayMath[a][b].forEach((m) => {
        math = math + ' ' + m;
    });
    this.updateTree(math, a, 'math', b);
    return math;
}
export function rozdzielDzialanie(dzialanie) {
    // Usuń wszystkie spacje z działania
    dzialanie = dzialanie.replace(/\s/g, '');

    // Utwórz tablicę do przechowywania wartości i operatorów
    const wynik = [];

    // Zmienna do przechowywania aktualnie analizowanego fragmentu liczby
    let liczba = '';

    // Przejdź przez każdy znak działania
    for (let i = 0; i < dzialanie.length; i++) {
        const znak = dzialanie[i];

        // Sprawdź, czy aktualny fragment pasuje do wzorców '2_??_C' lub '19_12_W' itd.
        const regexPattern = /^([A-Za-z\d]{1,3}_){0,2}[A-Za-z\d]{1,3}_\d{1,3}\s$/;
        const matchedPattern = liczba + znak;

        if (matchedPattern.match(regexPattern)) {
            liczba = matchedPattern; // Pomijamy wartość, która pasuje do wzorców i przechodzimy do kolejnego fragmentu
        } else if (znak === '+' || znak === '*' || znak === '-' || znak === '/') {
            wynik.push(liczba.replace(',', '.'));
            wynik.push(znak);
            liczba = ''; // Resetuj zmienną liczba, aby analizować kolejną liczbę
        } else {
            liczba += znak;
        }
    }

    // Dodaj ostatnią liczbę do wyniku
    wynik.push(liczba.replace(',','.'));

    return wynik;
}

